<template>
    <div>

        <!--Are You Sure Delete Modal-->
        <b-modal no-close-on-backdrop scrollable ok-title="Submit" title="Confirm Delete" class="modal-dark"
                 v-model="areYouSureDelete">
            <p>Are you sure to delete the "{{ currentFunction.title }}" camera function?</p>
            <template slot="modal-footer">
                <b-button size="sm" variant="secondary" @click="areYouSureDelete=false">Cancel</b-button>
                <b-button size="sm" variant="danger" @click="closeAreYouSureDelete()">Submit</b-button>
            </template>
        </b-modal>

        <!--Add Camera Functions Modal-->
        <b-modal no-close-on-backdrop scrollable ok-title="Submit" title="Create a Function" class="modal-dark"
                 v-model="addFunction">
            <div v-for="field in fields" :key="field.name">
                <label class="form-check-label">{{ field.label }}:</label>
                <input :placeholder="field.placeholder||field.label" @keyup.enter="closeAddFunction()"
                       class="form-control"
                       v-bind:class="{ 'border-danger': ! newFunctionInfo[field.name] && field.required}"
                       v-model="newFunctionInfo[field.name]">
                <br>
            </div>
            <template slot="modal-footer">
                <b-button size="sm" variant="secondary" @click="addFunction=false">Cancel</b-button>
                <b-button size="sm" variant="primary" :disabled="createFormDisabledOk()" @click="postFunctions">Submit
                </b-button>
            </template>
        </b-modal>

        <!--Edit Camera Functions Modal-->
        <b-modal no-close-on-backdrop scrollable ok-title="Submit" title="Function Editor" class="modal-dark"
                 v-model="editFunction">
            <div v-for="field in fields" :key="field.name">
                <label class="form-check-label">{{ field.label }}:</label>
                <input :placeholder="field.placeholder||field.label" @keyup.enter="closeEditFunction()"
                       class="form-control"
                       v-bind:class="{ 'border-danger': ! currentFunction[field.name] && field.required}"
                       v-model="currentFunction[field.name]">
                <br>
            </div>
            <b-button @click="areYouSureDelete=true" block variant="danger">Delete Function</b-button>
            <br>
            <div v-if="currentFunction.created_date">
                <p style="text-align:center; color: #7f8c8d; font-size: small">Date Created: {{ readableDate }}</p>
            </div>
            <template slot="modal-footer">
                <b-button size="sm" variant="secondary" @click="editFunction=false; getFunctions()">Cancel</b-button>
                <b-button size="sm" variant="primary" :disabled="editFormDisabledOk()" @click="putFunctions">Submit
                </b-button>
            </template>
        </b-modal>

        <!--Edit Devices Modal-->
        <b-modal no-close-on-backdrop scrollable ok-title="Submit" title="Device Editor" size="xl" class="modal-dark"
                 v-model="editDevice">
            <template slot="modal-title">
                <div>
                    Device Editor
                </div>
            </template>
            <v-client-table style="width: 100%" :columns="functionForEditColumns" :data="functions"
                            :options="functionForEditOptions" :theme="theme" class="dataTable">
                <b-form-checkbox slot-scope="props" slot="check" v-model="functionsSelected"
                                 :value="{make: props.row.make, model: props.row.model, title: props.row.title, url: props.row.url, icon: props.row.icon} || {make: props.row.make, title: props.row.title, url: props.row.url, icon: props.row.icon}"
                                 style="float: right"></b-form-checkbox>
                <div slot="h__check">
                    <b-form-checkbox style="float: right" type="checkbox" class="check-all" v-model='functionsAllMarked'
                                     @change="unmarkAndMarkAll()"></b-form-checkbox>
                </div>
            </v-client-table>
            <template slot="modal-footer">
                <b-button size="sm" variant="secondary" @click="editDevice=false">Cancel</b-button>
                <b-button size="sm" variant="primary" @click="putDevice(functionsSelected, currentDevice, true)">
                    Submit
                </b-button>
            </template>
        </b-modal>

        <!--Edit Multiple Devices Modal-->
        <b-modal no-close-on-backdrop scrollable ok-title="Submit" title="Device Editor" size="xl" class="modal-dark"
                 v-model="editDevice2">
            <template slot="modal-title">
                <div>Device Editor</div>
            </template>
            <div class="row">
                <div class="col-2">
                    <p>Devices:</p>
                    <div v-for="(i, index) in selected2" :key="index">
                        <p style="line-height: 1;margin-bottom: 0">IP: {{ i.ip }}</p>
                    </div>
                </div>
                <div class="col-10">
                    <v-client-table style="width: 100%" :columns="functionForEditColumns" :data="functions"
                                    :options="functionForEditOptions" :theme="theme" class="dataTable">
                        <b-form-checkbox slot-scope="props" slot="check" v-model="functionsSelected"
                                         :value="{make: props.row.make, model: props.row.model, title: props.row.title, url: props.row.url, icon: props.row.icon} || {make: props.row.make, title: props.row.title, url: props.row.url, icon: props.row.icon}"
                                         style="float: right"></b-form-checkbox>
                        <div slot="h__check">
                            <b-form-checkbox style="float: right" type="checkbox" class="check-all"
                                             v-model='functionsAllMarked'
                                             @change="unmarkAndMarkAll()"></b-form-checkbox>
                        </div>
                    </v-client-table>
                </div>
            </div>
            <template slot="modal-footer">
                <b-button size="sm" variant="secondary" @click="editDevice2=false">Cancel</b-button>
                <b-button size="sm" variant="primary" @click="multiPutDevice(selected2)">Submit</b-button>
            </template>
        </b-modal>

        <!--Are You Sure You Want To Delete multiple Pop-up-->
        <b-modal no-close-on-backdrop scrollable ok-title="Submit" title="Confirm Delete" class="modal-dark"
                 v-model="multiDelete">
            <p>If you would like to permanently remove these devices please enter this word.</p>
            <p style="margin-bottom: 0">Functions:</p>
            <ul>
                <div v-for="(i, index) in selected" :key="index">
                    <li>
                        <p style="margin-bottom: 0"> Name: {{ i.title }}</p>
                        <p style="margin-bottom: 0"> ID: {{ i.id }}</p>
                        <p style="margin-bottom: 0"> URL: {{ i.url }}</p>
                    </li>
                </div>
            </ul>
            <br>
            <p style="margin-bottom: 0">Enter "Delete":</p>
            <b-form-group description="case sensitive">
                <b-form-input @keyup.enter="multiDeleteEnterButton()" v-model="nameVerification" id="basicName1"
                              type="text"></b-form-input>
            </b-form-group>
            <template slot="modal-footer">
                <b-button size="sm" variant="secondary" @click="multiDelete=false">Cancel</b-button>
                <b-button size="sm" :disabled="nameVerification !== 'Delete'" variant="danger"
                          @click="multiDeleteFunctions(selected)">Submit
                </b-button>
            </template>
        </b-modal>

        <!--Main Page-->
        <b-card style="width: 100%; height: 100%;">
            <div slot="header" no-body>
                <span class="d-flex w-100 justify-content-between">
                    <h5 style="margin-bottom: 0" @click="editMode"><b>Camera Functions</b></h5>
                    <fa-icon v-if="selected.length !== 0" :icon="['fas', 'trash']" style="float: right"
                       @click="multiDelete = true; nameVerification = ''"/>
                    <fa-icon v-else :icon="['far', 'square-plus']" style="float: right" @click="openAddFunction()"/>
                </span>
            </div>
            <v-client-table style="width: 100%" @row-click="rowClicked" ref="functionsTable" :columns="functionColumns"
                            :data="functions" :options="functionOptions" :theme="theme" class="dataTable">
                <a slot="edit" slot-scope="props" target="_blank" :href="props.row.info"
                   style="float: right; cursor: pointer" @click="openEditFunction(props.row)">
                    <fa-icon :icon="['fas', 'pen-to-square']"/>
                </a>
                <div slot="h__edit">
                    <p style="float: right; margin-bottom: 0">Edit</p>
                </div>
                <div slot="icon" slot-scope="props">
                    <span> <i style="cursor:auto"
                              :class="props.row.icon + ' icons font-lg'"></i>  &nbsp;&nbsp; {{ props.row.icon }}</span>
                </div>
                <b-form-checkbox style="text-align: center" slot-scope="props" slot="check" v-model="selected"
                                 :value="{id: props.row._id, make: props.row.make, model: props.row.model, title: props.row.title, url: props.row.url, icon: props.row.icon}"></b-form-checkbox>
                <div slot="h__check" style="text-align: center">
                    <b-form-checkbox type="checkbox" style="padding-left: 24px" class="check-all" v-model='allMarked'
                                     @change="selected = unmarkMarkAll(selected, allMarked)"></b-form-checkbox>
                </div>
            </v-client-table>
        </b-card>
        <b-card style="width: 100%; height: 100%;">
            <div slot="header" no-body>
        <span class="d-flex w-100 justify-content-between">
          <h5 @click="editMode2" style="margin-bottom: 0"><b>Cameras</b></h5>
          <fa-icon v-if="selected2.length !== 0" :icon="['far', 'pen-to-square']" style="float: right"
             @click="editDevice2 = true; nameVerification = ''"/>
          <p v-else> </p>
        </span>
            </div>
            <v-client-table style="width: 100%" @row-click="rowClicked" ref="devicesTable" :columns="deviceColumns"
                            :data="devices" :options="deviceOptions" :theme="theme" class="dataTable">
                <a slot="edit" slot-scope="props" target="_blank" :href="props.row.info"
                   style="float: right; cursor: pointer;" @click="openEditDevice(props.row)">
                    <fa-icon :icon="['fas', 'pen-to-square']"/>
                </a>
                <div slot="endpoints" slot-scope="props">
                    <p>{{ (props.row.endpoints.map(a => " " + a.title)).toString() }}</p>
                </div>
                <div slot="h__edit">
                    <p style="float: right; margin-bottom: 0">Edit</p>
                </div>
                <b-form-checkbox style="text-align: center" slot-scope="props" slot="check" v-model="selected2"
                                 :value="{_id: props.row._id, ip: props.row.ipAddress, constellationurl: props.row.constellationurl}"></b-form-checkbox>
                <div slot="h__check" style="text-align: center">
                    <b-form-checkbox type="checkbox" style="padding-left: 24px" class="check-all" v-model='allMarked2'
                                     @change="selected2 = unmarkMarkAll2(selected2, allMarked2)"></b-form-checkbox>
                </div>
            </v-client-table>
            <b-tooltip v-if="tooltipRender" ref="tooltip" :target="target" :title="tooltipTitle"
                       triggers=""></b-tooltip>
        </b-card>

    </div>
</template>

<script>
import constellations from '@/services/constellation';
import fcr from '@/services/fcr';
import Vue from 'vue'
import {ClientTable} from 'vue-tables-2'
import constellationStore from "@/store/constellationStore";
import deviceStore from "@/store/deviceStore";

Vue.use(ClientTable);

export default {
    data: () => {
        return {
            tooltipTitle: "",
            tooltipRender: true,
            target: '',
            //Data Table
            useVuex: false,
            theme: 'bootstrap4',
            template: 'default',
            //Data Table Camera Functions
            functionColumns: ['make', 'model', 'title', 'url', 'icon', 'edit'],
            functionOptions: {
                orderBy: {column: 'title', ascending: true},
                headings: {
                    make: 'Make',
                    model: 'Model',
                    title: 'Name',
                    url: 'URL',
                    edit: 'Edit',
                },
                sortable: ['make', 'model', 'title', 'icon', 'url'],
                filterable: ['make', 'model', 'title', 'url', 'icon'],
                sortIcon: {base: 'fas', up: 'fa-sort-up', down: 'fa-sort-down', is: 'fa-sort'},
                pagination: {
                    chunk: 5,
                    edge: false,
                    nav: 'scroll'
                },
                perPage: 25,
                skin: 'table table-striped table-hover',
            },
            //Data Table Devices
            deviceColumns: ['constellation', 'friendlyName', 'ipAddress', 'make', 'betterEndpoints', 'edit'],
            deviceOptions: {
                orderBy: {column: 'ipAddress', ascending: true},
                headings: {
                    constellation: 'Constellation',
                    friendlyName: 'Device Name',
                    ipAddress: 'IP',
                    make: 'Make',
                    betterEndpoints: 'Endpoints',
                    edit: 'Edit',
                },
                sortable: ['constellation', 'friendlyName', 'ipAddress', 'make', 'betterEndpoints'],
                filterable: ['constellation', 'friendlyName', 'ipAddress', 'make', 'betterEndpoints'],
                sortIcon: {base: 'fas', up: 'fa-sort-up', down: 'fa-sort-down', is: 'fa-sort'},
                pagination: {
                    chunk: 5,
                    edge: false,
                    nav: 'scroll'
                },
                perPage: 25,
                skin: 'table table-striped table-hover',
            },
            //Data Table For Edit Devices
            functionForEditColumns: ['check', 'make', 'model', 'title', 'url'],
            functionForEditOptions: {
                orderBy: {column: 'title', ascending: true},
                headings: {
                    make: 'Make',
                    model: 'Model',
                    title: 'Name',
                    url: 'URL',
                },
                sortable: ['make', 'model', 'title', 'url'],
                filterable: ['make', 'model', 'title', 'url'],
                sortIcon: {base: 'fas', up: 'fa-sort-up', down: 'fa-sort-down', is: 'fa-sort'},
                pagination: {
                    chunk: 5,
                    edge: false,
                    nav: 'scroll'
                },
                perPage: 10,
                skin: 'table table-striped table-hover',
            },
            //Modals
            multiDelete: false,
            areYouSureDelete: false,
            addFunction: false,
            editFunction: false,
            editDevice2: false,
            editDevice: false,
            //Check Marks
            selected: [],
            selected2: [],
            allMarked: false,
            allMarked2: false,
            multiSelect: false,
            multiSelect2: false,
            multiSelect3: true,
            multiDeleteCount: 0,
            //Other Variables
            nameVerification: '',
            functionsAllMarked: false,
            functionsSelected: [],
            devices: [],
            functions: [],
            constellations: [],
            constellationnames: [],
            constellationnames2: [],
            currentFunction: {},
            currentDevice: {},
            newFunctionInfo: {},
            fields: [
                {label: 'Make', name: 'make', required: true,},
                {label: 'Model', name: 'model', required: false},
                {label: 'Name', name: 'title', required: true},
                {label: 'URL', name: 'url', required: true, placeholder: "/axis/user/date?action=get"},
                {label: 'Icon', name: 'icon', required: true, placeholder: "fa fa-arrows"},
                {label: 'Type', name: 'type', required: true, placeholder: "GET/POST"},
                {label: 'Data', name: 'data', required: false, placeholder: "{}"},
            ],
            readableDate: '',
        }
    },
    created() {
        this.getAllDevices();
        this.getFunctions()
    },
    methods: {
        rowClicked(item) {
            //ctrl click to make tooltip appear with id
            if (item.event.ctrlKey) {
                this.tooltipRender = false;
                this.tooltipTitle = item.row._id;
                item.event.target.id = item.row._id;
                this.target = item.row._id;
                //restart tooltip so it can map to its new position
                Vue.nextTick().then(() => {
                    this.tooltipRender = true;
                    Vue.nextTick().then(() => {
                        this.$refs.tooltip.$emit('open');
                        setTimeout(function () {
                            //make the tooltip disapear automatically
                            this.$refs.tooltip.$emit('close');
                        }.bind(this), 4000)
                    });
                });
            }
        },
        //Check Marks
        editMode() {
            if (!this.multiSelect) {
                this.functionColumns = ['check', 'make', 'model', 'title', 'url', 'icon', 'edit'];
                this.multiSelect = true;
            } else {
                this.functionColumns = ['make', 'model', 'title', 'url', 'icon', 'edit'];
                this.multiSelect = false;
                this.selected = [];
            }
        },
        editMode2() {
            if (!this.multiSelect2) {
                this.deviceColumns = ['check', 'constellation', 'friendlyName', 'ipAddress', 'make', 'betterEndpoints', 'edit'];
                this.multiSelect2 = true;
            } else {
                this.deviceColumns = ['constellation', 'friendlyName', 'ipAddress', 'make', 'betterEndpoints', 'edit'];
                this.multiSelect2 = false;
                this.selected2 = [];
            }
        },
        unmarkMarkAll(selected, allMarked) {
            if (allMarked === false) {
                let temp = [];
                if (this.$refs.functionsTable.allFilteredData) {
                    this.$refs.functionsTable.allFilteredData.forEach(function (item) {
                        temp.push({
                            id: item._id,
                            make: item.make,
                            model: item.model,
                            title: item.title,
                            url: item.url,
                            icon: item.icon
                        });
                    });
                }
                return (temp)
            } else {
                return ([])
            }
        },
        unmarkMarkAll2(selected2, allMarked2) {
            if (allMarked2 === false) {
                let temp = [];
                if (this.$refs.devicesTable.allFilteredData) {
                    this.$refs.devicesTable.allFilteredData.forEach(function (item) {
                        temp.push({_id: item._id, ip: item.ipAddress, constellationurl: item.constellationurl});
                    });
                }
                return (temp)
            } else {
                return ([])
            }
        },
        //FCR and Axios
        getAllDevices() {
            this.devices = [];
            constellationStore.dispatch('getConstellations').then(response1 => {
                this.constellations = response1;
                this.constellations.forEach(function (constellation) {
                    this.constellationnames.push(constellation.constellationname);
                    this.constellationnames2.push({
                        name: constellation.constellationname,
                        address: (constellation.url + ":" + constellation.port)
                    });
                    deviceStore.dispatch('getSmartSuiteDevices', constellation._id).then(response => {
                    //constellations.readDeviceCameraList(constellation.url + ":" + constellation.port).then(response => {
                        if (Array.isArray(response)) {
                            response.forEach(function (eachDevice) {
                                eachDevice.constellation = constellation.constellationname;
                                eachDevice.constellationurl = (constellation.url + ":" + constellation.port);
                                eachDevice.betterEndpoints = (eachDevice.endpoints.map(a => " " + a.title)).toString()
                            });
                            this.devices = (this.devices).concat(response);
                        }
                    });
                }.bind(this));
            })/*.catch(error => {
                this.$mToast({
                    title: error.response.status + ' Error',
                    text: "Constellations couldn't be retrieved: " + error.response.statusText,
                    style: 'error'
                });
            });*/
        },
        getFunctions() {
            fcr.getFunctions().then(response => {
                var temp = [];
                response.data.forEach(function (i) {
                    if (!i.model) {
                        i.model = " ";
                    }
                    temp = temp.concat(i);
                });
                this.functions = temp;
            }).catch(error => {
                this.$mToast({
                    title: error.response.status + ' Error',
                    text: "Functions couldn't be retrieved: " + error.response.statusText,
                    style: 'error'
                });
            })
        },
        postFunctions() {
            this.addFunction = false;
            fcr.postFunctions(this.newFunctionInfo).then(() => {
                this.$mToast({
                    title: 'Success',
                    text: "Camera function created",
                    style: 'success'
                });
                this.clearObjectData();
                this.getAllDevices();
                this.getFunctions();
            }).catch(error => {
                this.$mToast({
                    title: error.response.status + ' Error',
                    text: "Camera fucntions couldn't be created: " + error.response.statusText,
                    style: 'error'
                });
            })
        },
        putFunctions() {
            this.editFunction = false;
            fcr.putFunctions(this.currentFunction._id, this.currentFunction).then(() => {
                this.$mToast({
                    title: 'Success',
                    text: "Camera function updated",
                    style: 'success'
                });
                this.clearObjectData();
                this.getAllDevices();
                this.getFunctions();
            }).catch(error => {
                this.$mToast({
                    title: error.response.status + ' Error',
                    text: "Camera functions couldn't be updated: " + error.response.statusText,
                    style: 'error'
                });
            })
        },
        deleteFunctions(id) {
            fcr.deleteFunctions(id).then(() => {
                this.clearObjectData();
                this.getAllDevices();
                this.getFunctions();
                this.$mToast({
                    title: 'Success',
                    text: "Camera function deleted",
                    style: 'success'
                });
            }).catch(error => {
                this.$mToast({
                    title: error.response.status + ' Error',
                    text: "Camera functions couldn't be deleted: " + error.response.statusText,
                    style: 'error'
                });
            });
        },
        multiDeleteEnterButton() {
            if (this.nameVerification === 'Delete') {
                this.multiDeleteFunctions(this.selected);
            }
        },
        multiDeleteFunctions(selected) {
            this.multiDelete = false;
            this.multiDeleteCount = 0;
            selected.forEach(function (i) {
                this.multiDeleteCount++;
                this.deleteFunctions(i.id, this.multiDeleteCount === selected.length)
            }.bind(this));
            this.multiDelete = false;

        },
        putDevice(functionsSelected, device, refresh) {
            functionsSelected.forEach(function (i) {
                if (!i.model) {
                    delete i.model
                }
            });
            constellations.updateDeviceEndpoints(device.constellationurl, device._id, {endpoints: this.functionsSelected}).then(() => {
                if (refresh === true) {
                    this.clearObjectData();
                    this.getAllDevices();
                    this.getFunctions();
                }
            }).catch(error => {
                this.$mToast({
                    title: error.response.status + ' Error',
                    text: "Device couldn't be updated: " + error.response.statusText,
                    style: 'error'
                });
            })
            this.editDevice = false;
        },
        multiPutDevice(selected) {
            this.editDevice2 = false;
            selected.forEach(function (i) {
                this.putDevice(this.functionsSelected, i, false)
            }.bind(this));
            this.clearObjectData();
            this.getAllDevices();
            this.getFunctions();
        },
        //Modals
        //Are You Sure You Want To Delete Modal
        closeAreYouSureDelete() {
            this.areYouSureDelete = false;
            this.editFunction = false;
            this.deleteFunctions(this.currentFunction._id);
        },
        //Add Camera Functions Modal
        openAddFunction() {
            this.clearTempData();
            this.addFunction = true;
        },
        closeAddFunction() {
            if (!this.createFormDisabledOk()) {
                this.addFunction = false;
                this.postFunctions();
            }
        },
        createFormDisabledOk() {
            for (let field of this.fields) {
                if (field.required === true && (this.newFunctionInfo[field.name] === "" || this.newFunctionInfo[field.name] === undefined)) {
                    return true;
                }
            }
            return false;
        },
        //Edit Camera Functions Modal
        openEditFunction(currentFunction) {
            this.clearTempData();
            this.currentFunction = JSON.parse(JSON.stringify(currentFunction));
            this.makeDate(currentFunction.created_date);
            this.editFunction = true
        },
        closeEditFunction() {
            if (!this.editFormDisabledOk()) {
                this.editFunction = false;
                this.putFunctions();
            }
        },
        editFormDisabledOk() {
            for (let field of this.fields) {
                if (field.required === true && (this.currentFunction[field.name] === "" || this.currentFunction[field.name] === undefined)) {
                    return true;
                }
            }
            return false;
        },
        //Edit Device Modal
        openEditDevice(currentDevice) {
            this.functionsSelected = [];
            this.clearTempData();
            this.functionsSelected = currentDevice.endpoints;
            this.currentDevice = currentDevice;
            if (currentDevice.endpoints.length === this.functions.length) {
                this.functionsAllMarked = true
            }
            this.editDevice = true
        },
        unmarkAndMarkAll() {
            if (this.functionsAllMarked === false) {
                let allFunctions = [];
                this.functions.forEach(function (p) {
                    allFunctions.push({make: p.make, model: p.model, title: p.title, url: p.url, icon: p.icon})
                });
                this.functionsSelected = allFunctions
            } else {
                this.functionsSelected = []
            }
        },
        //Other Functions
        makeDate(time) {
            let created_date = time;
            let date = [];
            let months = ["", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
            date.push(created_date.substring(0, 4));
            date.push(created_date.substring(5, 7));
            date.push(created_date.substring(8, 10));
            date.push(created_date.substring(11, 16));
            let mlong = months[parseInt(date[1])];

            function tConvert(time) {
                time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
                if (time.length > 1) {
                    time = time.slice(1);
                    time[5] = +time[0] < 12 ? 'AM' : 'PM';
                    time[0] = +time[0] % 12 || 12;
                }
                return time.join('');
            }

            date[3] = tConvert(date[3])
            this.readableDate = mlong + " " + date[2] + ", " + date[0] + " at " + date[3] + " UTC"
        },
        clearTempData() {
            this.readableDate = '';
            this.currentFunction = {};
            this.currentDevice = {};
            this.newFunctionInfo = {};
            this.functionsAllMarked = false;
            this.selected2 = [];
            this.selected = [];
            this.allMarked = false;
            this.allMarked2 = false;
            this.multiSelect = false;
            this.multiSelect2 = false;
            this.multiSelect3 = false;
            this.functionsSelected = [];
            this.deviceColumns = ['constellation', 'friendlyName', 'ipAddress', 'make', 'betterEndpoints', 'edit'];
            this.functionColumns = ['make', 'model', 'title', 'url', 'icon', 'edit'];
            this.nameVerification = '';
        },
        clearObjectData() {
            this.devices = [];
            this.functions = [];
            this.constellations = [];
        },
    }
}
</script>
